import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { breakpoints, Section } from '~styles/global'
const { mobile } = breakpoints

const Blob = ({ className }) => (
  <div css={css`
      background: currentColor;
      fill: currentColor;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      @media(max-width: 1024px){
        border-radius: 18px;
      }
    `} className={className}>
  </div>
)

const CloudButton = ({ children, to, className }) => {
  const buttonRef = React.useRef()
  const [blobNum, setBlobNum] = useState(0)

  useLayoutEffect(() => {
    const bWidth = buttonRef.current?.offsetWidth
    if(window.innerWidth > 1024){
      setBlobNum(Math.ceil(bWidth / 150))
    } else {
      setBlobNum(Math.ceil(bWidth / 90))
    }
  }, [children])

  return (
    <Section className={className} css={css`
      padding: 60px 0 60px;
    `}>
      <div css={css`
        grid-column: span 12;
      `}>
        <Link to={to}>
        <button ref={buttonRef}
          css={css`
            color: var(--white);
            padding: 0.75em 0.5em;
            position: relative;
            margin: 0 auto;
            display: block;
            svg{
              display: block;
            }
          `}
          className="h1"
        >
          <span css={css`
            position: relative;
            z-index: 2;
          `}>
          {children}
          </span>
          <div css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            color: var(--pink);
            fill: var(--pink);
            z-index: 1;
            display: flex;
            transition: color 0.3s;
            button:hover & {
              color: var(--lightPink);
              fill: var(--lightPink);
            }
          `}>
            {[...Array(blobNum)].map((a, n) => (
              <Blob />
            ))}
            <div css={
              css`
              position: absolute;
              width: 100px;
              height: 100px;
              right: -50px;
              top: 50%;
              transform: translateY(-50%);
              background: currentColor;
              fill: currentColor;
              border-radius: 30px;
              ${mobile}{
                width: 50px;
                height: 50px;
                right: -25px;
              }
              `
            } />
            <div css={
              css`
              position: absolute;
              width: 100px;
              height: 100px;
              left: -50px;
              top: 50%;
              transform: translateY(-50%);
              background: currentColor;
              fill: currentColor;
              border-radius: 30px;
              ${mobile}{
                width: 50px;
                height: 50px;
                left: -25px;
              }
              `
            } />
          </div>
        </button>
        </Link>
      </div>
    </Section>
  )
}

CloudButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string
}

export default CloudButton
